import type { HeadFC } from "gatsby";

import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState, useLayoutEffect } from "react";

import { gsap } from "gsap";
import { withPrefix } from "gatsby";
import { isMobileOnly, isTablet } from "react-device-detect";
import { Text } from "../Text";
import DessertRoz from "../../assets/svgs/DessertRoz";
import { PageLoader } from "../PageLoader";
import { breakpoints } from "../../utils/breakpoints";
import { PageBackground } from "../PageBackground";
import { JV_Z_INDICES } from "../Jamunverse/constants";

const JeepHomeComponent = ({
    showRotateMessage
}: {
    showRotateMessage: boolean;
}) => {
    const backgroundImageRef = useRef();
    const mainElRef = useRef();
    const glowRef = useRef();

    const [pageLoaded, setPageLoaded] = useState(false);

    let a0;
    let a1;
    let a2;
    let tl;

    useEffect(() => {
        setPageLoaded(true);

        a0 = gsap.fromTo(
            mainElRef.current,
            { autoAlpha: 0, duration: 0.3 },
            {
                autoAlpha: 1
            }
        );
        a1 = gsap.to(backgroundImageRef.current, {
            rotation: "-=360",
            ease: "none",
            repeat: -1,
            repeatDelay: 0,
            duration: 300
        });
        a2 = gsap.fromTo(
            glowRef.current,
            { scale: "0.9" },
            {
                scale: "1.09",
                yoyo: true,
                yoyoEase: "power1.in",
                repeat: -1,
                repeatDelay: 0,
                duration: 6
            }
        );
        const q = gsap.utils.selector(mainElRef);

        tl = gsap.timeline({
            repeat: -1,
            repeatDelay: 2,
            delay: 1.5
            // paused: true
        });

        tl.fromTo(
            q(".gj-jeep-container"),
            {
                xPercent: -62.5,
                yPercent: 25,
                scale: 1,
                autoAlpha: 0
            },
            {
                keyframes: {
                    "0%": {
                        autoAlpha: 0
                    },
                    "30%": {
                        autoAlpha: 1
                    },
                    ease: "power1.out"
                },
                duration: 3,
                ease: "back.out(2)",
                xPercent: 125,
                yPercent: -50
            }
        );

        tl.to(q(".gj-jeep-container"), {
            keyframes: {
                autoAlpha: [1, 0],
                ease: "power4.out"
            },
            scale: 0.4,
            duration: 1,
            ease: "power4.out",
            xPercent: 375,
            yPercent: -150
        });

        tl.set(q(".gj-jeep-container"), { x: 0, y: 0 }, ">");

        // tl.fromTo(q(".gj-jeep-container"), {x: "250%", y: "-100%", immediateRender: false}, {
        //     x: 0,
        //     y: 0,
        //     keyframes: {
        //         autoAlpha: [0, 0, 0, 0, 0, 1, 0.2, 0.2, 0.9],
        //         scale: [0.8, 0.2, 1],
        //         ease: "power2.inOut"
        //     },
        //     duration: totalDuration / 2,
        //     ease: "power2.inOut"
        // });

        return () => {
            a0 && a0.kill();
            a1 && a1.kill();
            a2 && a2.kill();
            tl && tl.kill();
        };
    }, []);

    return (
        <div>
            {/* <Head> */}
            {/*    <title>Gulab Jamun | Dessert Roz</title> */}
            {/*    <meta name="description" */}
            {/*          content="Dessert Roz is a celebration of desserts & mithai from across South Asia."/> */}
            {/*    <link rel="icon" href="../images/favicon.png"/> */}
            {/* </Head> */}

            <main>
                <PageLoader active={!pageLoaded}>
                    <div
                        css={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            ".gj-main-bg-container": {
                                zIndex: 0,
                                position: "absolute",
                                top: "-100vh",
                                left: "-100vw",
                                right: "0",
                                bottom: "0",
                                width: "300vw",
                                height: "300vh",
                                ".gj-main-bg": {
                                    width: "100%",
                                    height: "100%",
                                    backgroundRepeat: "repeat",
                                    backgroundSize: "160vw 160vh"
                                }
                            },
                            ".content": {
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 2
                            },
                            ".gj-gulab-jamun-sphere-container": {
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                ".gj-gulab-jamun-sphere-and-glow": {
                                    width: "400px",
                                    maxWidth: "70vw",
                                    position: "relative",
                                    marginTop: "-7%",
                                    ".gj-gulab-jamun-sphere": {
                                        zIndex: 5,
                                        position: "absolute",
                                        top: "2%",
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        marginLeft: "20px"
                                    },
                                    ".gj-jeep-container": {
                                        zIndex: 12,
                                        position: "absolute",
                                        left: "-50%",
                                        width: "60%",
                                        height: "50%",
                                        top: "50%",
                                        ".gj-jeep": {
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat"
                                        },
                                        ".gj-jeep-side": {
                                            backgroundImage:
                                                "url(../../images/jeep.png)"
                                        }
                                    },

                                    ".gj-gulab-jamun-ring": {
                                        zIndex: 3,
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        marginLeft: "-50%",
                                        width: "200%"
                                    },
                                    ".gj-gulab-jamun-sphere-glow": {
                                        zIndex: 4
                                    }
                                }
                            },
                            ".gj-dessert-roz-logo-container": {
                                position: "absolute",
                                top: "15px",
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: "flex",
                                // alignItems: "center",
                                justifyContent: "center",
                                svg: {
                                    width: "100% !important"
                                },
                                ".gj-dessert-roz-logo": {
                                    // TODO - make responsive
                                    width: "160px"
                                },
                                [`${breakpoints.medium}`]: {
                                    ".gj-dessert-roz-logo": {
                                        // TODO - make responsive
                                        width: "200px",
                                        maxWidth: "60vw"
                                    }
                                }
                            },
                            ".gj-coming-soon-and-gulab-jamun-text": {
                                // TODO - make responsive
                                display: "grid",
                                alignContent: "start",
                                justifyItems: "center",
                                gridGap: "25px",
                                position: "absolute",
                                bottom: "18vh",
                                left: 0,
                                right: 0,
                                zIndex: 10,
                                ".gj-gulab-jamun-text": {
                                    width: "90vw",
                                    margin: `0 auto`
                                },
                                [`${breakpoints.medium}`]: {
                                    bottom: "15vh",
                                    ".gj-gulab-jamun-text": {
                                        width: "705px",
                                        maxWidth: "80vw"
                                    }
                                },
                                ".gj-coming-soon-text": {
                                    color: "#FFFFFF",
                                    display: "grid",
                                    alignItems: "center",
                                    gridTemplateColumns: "1fr",
                                    gridGap: "15px",
                                    margin: "0 2vw",
                                    textAlign: "center",
                                    [`${breakpoints.medium}`]: {
                                        gridTemplateColumns: "1fr"
                                    }
                                }
                            },
                            ".gj-menu": {
                                color: "#FFFFFF",
                                // TODO - make responsive
                                display: "grid",
                                justifyItems: "start",
                                position: "absolute",
                                justifyContent: "space-between",
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 10,
                                gridAutoFlow: "column",
                                padding: "2vmax 5vh",
                                ".gj-menu-item:hover": {
                                    transform: "skew(-5deg)"
                                },
                                [`${breakpoints.medium}`]: {
                                    top: 0,
                                    right: "initial",
                                    gridAutoFlow: "row",
                                    alignContent: "space-between"
                                }
                            }
                        }}
                        ref={mainElRef}
                    >
                        <div
                            css={{
                                position: "fixed",
                                bottom: "3vh",
                                left: "2vw",
                                zIndex: 202
                            }}
                        >
                            <a href={withPrefix("/kaju-katli.html")}>
                                <Text
                                    className="gj-menu-item"
                                    color="white"
                                    variant="mainNav"
                                >
                                    Kaju Katli
                                </Text>
                            </a>
                        </div>
                        <div
                            css={{
                                position: "fixed",
                                bottom: "3vh",
                                right: "2vw",
                                zIndex: 202
                            }}
                        >
                            <a href={withPrefix("/about.html")}>
                                <Text
                                    className="gj-menu-item"
                                    color="white"
                                    variant="mainNav"
                                >
                                    About
                                </Text>
                            </a>
                        </div>
                        <div className="gj-main-bg-container">
                            <div
                                ref={backgroundImageRef}
                                style={{
                                    backgroundImage: `url(${withPrefix(
                                        "/images/main-bg.png"
                                    )})`
                                }}
                                className="gj-main-bg"
                            />
                        </div>
                        <div className="content">
                            <div className="gj-dessert-roz-logo-container">
                                <div className="gj-dessert-roz-logo">
                                    <DessertRoz />
                                </div>
                            </div>
                            <div className="gj-gulab-jamun-sphere-container">
                                <div className="gj-gulab-jamun-sphere-and-glow">
                                    <div className="gj-jeep-container">
                                        <div className="gj-jeep gj-jeep-side">
                                            <StaticImage
                                                placeholder="none"
                                                loading="eager"
                                                src="../images/jeep.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="gj-gulab-jamun-sphere">
                                        <StaticImage
                                            placeholder="none"
                                            loading="eager"
                                            src="../images/gulab-jamun-sphere.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="gj-gulab-jamun-ring">
                                        <StaticImage
                                            placeholder="none"
                                            loading="eager"
                                            src="../images/spark.png"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        ref={glowRef}
                                        className="gj-gulab-jamun-sphere-glow"
                                    >
                                        <StaticImage
                                            placeholder="none"
                                            loading="eager"
                                            src="../images/glow.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="gj-coming-soon-and-gulab-jamun-text">
                                <div className="gj-coming-soon-text">
                                    <div className="gj-gulab-jamun-text">
                                        <StaticImage
                                            placeholder="none"
                                            loading="eager"
                                            src="../images/gulab-jamun-text.png"
                                            alt=""
                                        />
                                    </div>
                                    {/* <StaticImage
                                        placeholder="none"
                                        className="gj-coming-soon-streak"
                                        loading="eager"
                                        src="../images/coming-soon-streak.png"
                                        alt=""
                                    /> */}
                                    <Text variant="caption">
                                        {showRotateMessage
                                            ? "Rotate your device to enjoy this website"
                                            : "This website is made to be enjoyed on a larger screen."}
                                    </Text>
                                    {/* <StaticImage
                                        placeholder="none"
                                        className="gj-coming-soon-streak right"
                                        loading="eager"
                                        src="../images/coming-soon-streak.png"
                                        alt=""
                                    /> */}
                                </div>
                            </div>

                            {/* <div className="gj-menu">
                                <a href={withPrefix("/kaju-katli.html")}>
                                    <Text
                                        className="gj-menu-item"
                                        mixBlendMode="difference"
                                        variant="mainNav"
                                    >
                                        Kaju Katli
                                    </Text>
                                </a>
                                <a href={withPrefix("/about.html")}>
                                    <Text
                                        className="gj-menu-item"
                                        mixBlendMode="difference"
                                        variant="mainNav"
                                    >
                                        About
                                    </Text>
                                </a>
                            </div> */}
                        </div>
                    </div>
                </PageLoader>
            </main>

            <footer />
        </div>
    );
};

export const Head: HeadFC = () => <title>Gulab Jamun | Dessert Roz</title>;

export const DesktopOnlyPrompt = ({ children }: React.PropsWithChildren) => {
    const mainRef = useRef(null);

    const [isLandscapeLayout, setIsLandscapeLayout] = useState(false);
    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            // if (isMobile) {
            //     gsap.timeline({})
            //         .set(".gj-desktop-prompt", {
            //             display: "block",
            //             autoAlpha: 1
            //         })
            //         .call(() => {
            //             document.querySelector(".gj-main-content").remove();
            //         });
            // } else {
            gsap.timeline({}).to(mainRef.current, {
                autoAlpha: 1
            });
            // }
        }, mainRef);

        return () => ctx.revert();
    }, []);

    useLayoutEffect(() => {
        window.addEventListener("resize", () => {
            setIsLandscapeLayout(window.innerWidth > window.innerHeight);
        });
        setIsLandscapeLayout(window.innerWidth > window.innerHeight);
    }, []);

    return (
        <div
            ref={mainRef}
            css={{
                opacity: 0
            }}
        >
            {isMobileOnly || !isLandscapeLayout ? (
                <div
                    css={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 10000
                    }}
                    className="gj-desktop-prompt"
                >
                    <JeepHomeComponent showRotateMessage={isTablet} />
                </div>
            ) : (
                <>
                    <div
                        className="gj-main-content"
                        css={{
                            position: "relative",
                            // zIndex: JV_Z_INDICES.chaashni + 1
                        }}
                    >
                        {children}
                    </div>
                    <PageBackground />
                </>
            )}
        </div>
    );
};
